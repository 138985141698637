<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-row>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="被邀请电话">
          <el-input v-model="phone"></el-input>
        </el-form-item>
        <el-form-item label="被邀请姓名">
          <el-input v-model="name"></el-input>
        </el-form-item>
        <el-form-item label="邀请状态">
          <el-select
            v-model="value"
            placeholder="请选择"
            @change="invitation_choice"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="邀请类型">
          <el-select v-model="type" placeholder="请选择" clearable>
            <el-option
              v-for="item in typelist"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            style="margin-left: 50px"
            type="primary"
            @click="invitation_search"
            >搜索</el-button
          >
        </el-form-item>
      </el-form>
      <!-- 列表 -->
      <div>
        <el-table
          :data="invitationList"
          :fit="true"
          :cell-style="{ 'text-align': 'center' }"
          :header-cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            label="邀请人名称"
            prop="invitation_name"
          ></el-table-column>
          <el-table-column
            label="被邀请姓名"
            prop="coach_name"
          ></el-table-column>
          <el-table-column
            label="被邀请电话"
            prop="coach_phone"
          ></el-table-column>
          <el-table-column label="城市" prop="city"></el-table-column>
          <el-table-column label="类型" prop="city">
            <template slot-scope="{ row }">
              <p>
                {{ getTypeLable(row.type) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            label="邀请时间"
            prop="create_time"
          ></el-table-column>
          <el-table-column label="状态">
            <template slot-scope="{ row }">
              <p v-if="row.status == 0">邀请中</p>
              <p v-if="row.status == 1">邀请成功</p>
              <p v-if="row.status == 4">邀请成功-收益待到账</p>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging-fy
          @currentPageChange="invitationCurrentPage"
          :currentPage="invitationPage"
          :total="invitationTotal"
        ></paging-fy>
      </div>
    </el-row>
  </div>
</template>
      
<script>
export default {
  data() {
    return {
      nav: { firstNav: "教练管理中心", secondNav: "教练邀请列表" },
      invitationPage: 1,
      invitationTotal: 1,
      invitation_status: 2,
      invitationList: [],
      phone: "",
      type: 2,
      typelist: [
        {
          value: 2,
          label: "邀请注册",
        },
        {
          value: 3,
          label: "邀请开会员",
        },
        {
          value: 4,
          label: "邀请创建公会",
        },
      ],
      options: [
        {
          value: 2,
          label: "全部",
        },
        {
          value: 0,
          label: "邀请中",
        },
        {
          value: 1,
          label: "邀请成功",
        },
        {
          value: 4,
          label: "邀请成功-收益待到账",
        },
      ],
      value: "全部",
      name: "",
    };
  },
  mounted() {
    this.invitation();
  },
  methods: {
    getTypeLable(v) {
      for (let i = 0; i < this.typelist.length; i++) {
        if (this.typelist[i].value == v) {
          return this.typelist[i].label;
        }
      }
      return "";
    },
    invitationCurrentPage(v) {
      this.invitationPage = v;
      this.invitation();
    },
    invitation_choice(v) {
      this.invitation_status = v;
    },

    invitation_search() {
      this.invitation();
      this.invitationPage = 1;
    },
    // 邀请列表
    invitation() {
      let data = {
        // invitation_id: this.coach_id,
        type: this.type || null,
        pageSize: 10,
        currentPage: this.invitationPage,
      };
      if (this.invitation_status != 2) {
        data.status = this.invitation_status;
      }
      if (this.phone) {
        data.coach_phone = this.phone;
      }
      if (this.name) {
        data.coach_name = this.name;
      }
      this.$get("/user/coachInvitationRecord/queryManagerListPage", data).then(
        (res) => {
          this.invitationList = res.data.data.rows;
          this.invitationTotal = res.data.data.total;
        }
      );
    },
  },
};
</script>
      
<style lang="less">
.expand-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  font-size: 20px;
  line-height: 1.5;

  .el-form-item {
    width: 40%;
  }
}
</style>
      